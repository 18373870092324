import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTinyPopover from 'react-tiny-popover';

import styles from './Popover.module.scss';

const NotificationPopover = ({ children, className, onClick, content }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	useEffect(() => {
		if (isPopoverOpen) {
			setTimeout(() => setIsPopoverOpen(false), 3000);
		}
	}, [isPopoverOpen]);
	const handleOnClick = () => {
		// If we provide function to be called before opening a popover,
		// we want to check if it was executed with a success
		if (onClick) {
			if (onClick()) {
				setIsPopoverOpen(!isPopoverOpen);
			}
		} else {
			setIsPopoverOpen(!isPopoverOpen);
		}
	};
	return (
		<ReactTinyPopover
			isOpen={isPopoverOpen}
			onClickOutside={() => setIsPopoverOpen(false)}
			containerClassName={cx(styles.notificationPopover)}
			position="top"
			content={isPopoverOpen ? content : null}>
			<button className={className} onClick={handleOnClick}>
				{children}
			</button>
		</ReactTinyPopover>
	);
};

NotificationPopover.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	content: PropTypes.node.isRequired,
};

export default NotificationPopover;
