import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import CopyIcon from '../../assets/icons/file-copy.svg';
import { ContentLayout, QuickBottomNavigationWrapper } from '../../components/';
import Layout from '../../components/layout';
import NotificationPopover from '../../components/Popovers/NotificationPopover';
import SEO from '../../components/seo';
import Tooltip from '../../components/Tooltip/Tooltip';
import styles from '../../stylesheets/pages/newsroom.module.scss';
import { QUICK_NAVIGATION_CONTACT, copyToClipboard, getLocalizedDataFromContentfulEdges } from '../../utils';

const Contact = () => {
	const intl = useIntl();
	const { allContentfulPrContact, allContentfulSpecificPageMetaData } = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "pr-contact" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
			allContentfulPrContact {
				edges {
					node {
						node_locale
						text
						email
					}
				}
			}
		}
	`);
	const {
		node: { text, email },
	} = getLocalizedDataFromContentfulEdges(allContentfulPrContact.edges, intl.locale)[0];
	const { node } = getLocalizedDataFromContentfulEdges(allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	return (
		<>
			<SEO lang={intl.locale} title={node.title} description={node.description}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_CONTACT}>
					<ContentLayout>
						<div className={styles.newsroomPage}>
							<h1>{intl.formatMessage({ id: 'nav.pr_contact' })}</h1>
							<p>{text}</p>
							<NotificationPopover
								className={styles.contactButton}
								content={<p>{intl.formatMessage({ id: 'notification.copiedEmail' })}</p>}
								onClick={() => copyToClipboard(email)}>
								<Tooltip text={intl.formatMessage({ id: 'tooltip.copyEmail' })}>
									<CopyIcon />
									{email}
								</Tooltip>
							</NotificationPopover>
						</div>
					</ContentLayout>
				</QuickBottomNavigationWrapper>
			</Layout>
		</>
	);
};

export default Contact;
